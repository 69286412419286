<template>
  <fw-layout wide mobile-ready :notfound="notFound" :back-to-enable="false">
    <template #main-content>
      <fw-panel :title="$t('receipts')" featured>
        <fw-panel :title="$t('currentYear')" class="mt-5">
          <LoadingPlaceholder v-if="loadingCurrentYear" :list="false" />
          <div v-else-if="currentYearReceipt" class="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-5 gap-4 ">
            <button
              class="bg-white w-full h-full flex justify-center flex-col gap-3 p-4 shadow-lg hover:shadow-2xl rounded-xl cursor-pointer"
              :aria-label="$t('receipt')"
              :disabled="downloading || previewOpen"
              @click="openPreview(currentYearReceipt)"
            >
              <div v-if="currentYearIsNewReceipt" class="w-full inline-flex items-center justify-between relative">
                <fw-label color="text-primary" class="uppercase">{{ $t('new') }}</fw-label>
                <fw-dot class="h-2 w-2 top-2.5"></fw-dot>
              </div>
              <fw-icon-file-pdf v-else class="h-5 w-5 text-gray-500"></fw-icon-file-pdf>
              <div class="flex-1 flex items-center flex-col self-center mb-2">
                <h2 class="font-bold text-center px-2 mb-2">{{ year }}</h2>
              </div>
              <span class="text-gray-500 text-xs self-end">{{ getFileMediaType(currentYearReceipt) }}</span>
            </button>
          </div>
          <fw-panel-info v-else centered empty class="p-3">
            {{ $t('noReceipts') }}
          </fw-panel-info>
        </fw-panel>

        <fw-panel
          :title="$t('previousYears')"
          :counter="otherYearsReceipts ? otherYearsReceipts.length : 0"
          class="my-5"
        >
          <!-- <template v-if="!isObjectEmpty(receiptsCount)" #toolbar>
            <b-dropdown aria-role="list" position="is-bottom-left">
              <fw-button-dropdown
                slot="trigger"
                aria-role="listitem"
                type="xlight"
                :label="`${selectedYear ? selectedYear : $t('all')}`"
                class="font-medium text-gray-500"
              >
              </fw-button-dropdown>
              <b-dropdown-item
                v-for="(count, receiptYear) in receiptsCount"
                :key="receiptYear"
                :label="`${receiptYear}`"
                aria-role="listitem"
                :class="`font-medium ${receiptYear == selectedYear ? 'text-primary' : 'text-gray-500'}`"
                @click.native="getIrsByYear(receiptYear)"
              >
                {{ receiptYear }}
              </b-dropdown-item>
            </b-dropdown>
          </template> -->

          <LoadingPlaceholder v-if="loading" list />
          <div v-else-if="otherYearsReceipts && otherYearsReceipts?.length">
            <div v-for="irsReceipt in otherYearsReceipts" :key="irsReceipt.key" class="p-1 border-b border-gray-100">
              <RecordReceipt
                :item="irsReceipt"
                @download="downloadReceipt(irsReceipt)"
                @preview="openPreview(irsReceipt)"
              ></RecordReceipt>
            </div>
          </div>

          <fw-panel-info v-else centered empty class="p-3">
            {{ $t('noReceipts') }}
          </fw-panel-info>
        </fw-panel>
      </fw-panel>

      <fw-panel-info debug label="Receipts (raw)">
        <json-viewer
          :value="{
            currentYearReceipt,
            otherYearsReceipts,
            receiptsCount,
            selectedYear,
            previewOpen,
            selectedFile
          }"
        ></json-viewer>
      </fw-panel-info>
    </template>

    <template #modals>
      <fw-modal :active="previewOpen" size="7xl" width="100%" boxed="xs" custom-class="m-2" @close="closePreview">
        <PanelPDFviewer
          v-if="selectedFile"
          class="h-full"
          :file="{ ...selectedFile.file, token: user.token }"
          :title="`RV ${$t(`months.${selectedFile.month}`)} ${selectedFile.year}`"
          @close="closePreview"
        >
          <template #header>
            <div
              class="flex bg-gray-100 border-b border-gray-200 items-center p-3 pl-5 text-gray-800 absolute left-0 right-0 z-10"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
                class="fill-current h-5 w-5"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path
                  d="M12 16H8V8h4a4 4 0 1 1 0 8zm-2-6v4h2a2 2 0 1 0 0-4h-2zm5-6H5v16h14V8h-4V4zM3 2.992C3 2.444 3.447 2 3.999 2H16l5 5v13.993A1 1 0 0 1 20.007 22H3.993A1 1 0 0 1 3 21.008V2.992z"
                />
              </svg>
              <span class="font-semibold ml-2 text-gray-600">{{
                `RV ${$t(`months.${selectedFile.month}`)} ${selectedFile.year}`
              }}</span>

              <DownloadButton
                class="ml-auto"
                :no-margin="true"
                :download-total-size="selectedFile?.size"
                @download="downloadReceipt(selectedFile)"
              />
            </div>
          </template>
        </PanelPDFviewer>
      </fw-modal>
    </template>
  </fw-layout>
</template>

<script>
import RecordReceipt from '@/components/records/RecordReceipt'
import LoadingPlaceholder from '@/fw-modules/fw-core-vue/ui/components/animation/LoadingPlaceholder'
import PanelPDFviewer from '@/fw-modules/fw-core-vue/buckets/components/panels/PanelPDFviewer'
import DownloadButton from '@/fw-modules/fw-core-vue/ui/components/buttons/DownloadButton'
import ServiceSettings from '@/fw-modules/fw-core-vue/id/services/ServiceSettings'
import Dates from '@/fw-modules/fw-core-vue/utilities/dates'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'
import ServiceStorage from '@/fw-modules/fw-core-vue/storage/services/ServiceStorage'

export default {
  components: {
    RecordReceipt,
    LoadingPlaceholder,
    DownloadButton,
    PanelPDFviewer
  },

  data() {
    return {
      loading: true,
      loadingCurrentYear: true,
      downloading: false,
      year: Dates.now().year(),
      currentYearReceipt: {},
      otherYearsReceipts: {},
      receiptsCount: {},
      selectedYear: null,
      previewOpen: false,
      selectedFile: null
    }
  },

  computed: {
    api() {
      return this.$store.state.api.base
    },

    user() {
      return this.$store.getters.getUser
    },

    currentYearIsNewReceipt() {
      return this.currentYearReceipt && !this.currentYearReceipt.seen
    },

    debugmode() {
      return Boolean(localStorage.getItem('fw-debug'))
    },

    betamode() {
      return Boolean(localStorage.getItem('beta'))
    },

    notFound() {
      return !this.betamode && !this.debugmode
    }
  },

  created() {
    ServiceSettings.checkAppVersion()
  },

  mounted() {
    this.getIrs()
    // this.getIrsByYear(this.year - 1)
  },

  methods: {
    openPreview(file) {
      this.selectedFile = file
      this.previewOpen = true
      this.api.setUserReceiptSeen(file.key, true).then(() => {
        file.seen = true
      })
    },

    closePreview() {
      this.previewOpen = false
      this.selectedFile = null
    },

    async getIrs() {
      this.loadingCurrentYear = true
      this.loading = true

      await utils.tryAndCatch(this, async () => {
        const response = await this.api.getUserReceipts({
          type: 'irs'
        })
        console.log('getIrs :>> ', response)
        this.currentYearReceipt = response.receipts?.irs?.find(el => el.year == this.year)

        this.otherYearsReceipts = response.receipts?.irs?.filter(el => el.year != this.year)
        this.receiptsCount = response.years_count
      })

      this.loadingCurrentYear = false
      this.loading = false
    },

    downloadReceipt(file) {
      console.log('downloadReceipt :>> ', file)
      if (!file) return

      this.downloading = true

      this.api.setUserReceiptSeen(file.key, true).then(() => {
        file.seen = true
      })

      const url = ServiceStorage.getFileUrl(file.file, this.user.token)
      utils.downloadFile(url, file.file.filename)
      this.downloading = false
    },

    isNewReceipt(receipt) {
      return receipt.month == this.month && receipt.year == this.year && !receipt.seen
    },

    getFileMediaType(file) {
      if (file.filename) return '.' + file.filename.slice(file.filename.lastIndexOf('.') + 1)

      return file.type
    },

    isObjectEmpty(obj) {
      return utils.isObjectEmpty(obj)
    }
  }
}
</script>

<i18n>
{
  "pt": {
    "currentYear": "Ano atual",
    "previousYears": "Anos anteriores",
    "receipts": "Declarações IRS",
    "noReceipts": "Sem declarações IRS",
    "receipt": "Recibo",
    "new": "Novo",
    "all": "Todos"
  },
  "en": {
    "currentYear": "Current year",
    "previousYears": "Previous years",
    "receipts": "Pay slips",
    "noReceipts": "No pay slips",
    "new": "New",
    "all": "All"
  }
}
</i18n>
